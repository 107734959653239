import { Inject, Injectable } from '@angular/core';
import { firstValueFrom, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AuthService } from '../auth/auth.service';
import {
  AOEvent,
  AOEventResponse,
  AOEventsListRequestParams,
  AOEventParticipantsRequestParams,
  AOEventParticipantsResponse,
  AOEventParticipantType,
  AOEventsListResponse,
  AOEventsListHomePageResponse,
  AOEventsListHomePageRequestParams
} from '../yeti-protocol/event';
import { SchemaValidatorService } from '../schema-validator.service';
import appConfig from 'src/config/config';
import { AOEventsListResult } from './events.model';
import { toAuthRequestParams } from '../auth/logic/auth-logic.utils';
import { CONTEXT_SERVICE, ContextService } from '../context/context.model';
import { VerificationService } from '../verification.service';
import { AppNavController } from '../app-nav-controller.service';
import { VerificationStatus } from '../verification.model';
import { ActionSource } from '../yeti-protocol/tracking';
import { LinkOpenerService } from '../link-opener.service';

export interface EventsServiceConfig {
  serverUrlIonic: string;
  eventGroupsUrl: string;
  eventUrl: string;
}

export interface EventsResponse {
  status: boolean;
  result: Array<AOEvent>;
  totalItemsCount: number;
}

export const NOT_CONTEXT_SPECIFIC_AO_EVENT_SPECIALITY = 'foundation';

function responseToResult(res: AOEventsListResponse, start: number): AOEventsListResult {
  return {
    events: res.result,
    totalItemsCount: res.totalItemsCount,
    start
  };
}

@Injectable({
  providedIn: 'root'
})
export class EventsService {
  config: EventsServiceConfig = appConfig;

  constructor(
    private authService: AuthService,
    private schemaValidator: SchemaValidatorService,
    @Inject(CONTEXT_SERVICE) private contextService: ContextService,
    private verificationService: VerificationService,
    private appNavController: AppNavController,
    private linkOpenerService: LinkOpenerService,
  ) { }

  /** events from service */
  getEvents(
    start: number = 0,
    count: number = 5,
    onlyForLoggedIn: boolean = false,
    past: boolean = false,
    liveNow: boolean = false): Promise<AOEventsListResult> {
    return this.getCurrentContextKey()
      .then(contextKey => {
        const url = `${this.config.serverUrlIonic}events/events`;

        const params: AOEventsListRequestParams = {
          start: start,
          count: count,
          appId: contextKey,
          onlyForLoggedIn: onlyForLoggedIn,
          past: past,
          liveNow: liveNow
        };

        return firstValueFrom(this.authService.secureGet(url, {
          params: toAuthRequestParams(params)
        }).pipe(
          this.schemaValidator.isValidOperator<AOEventsListResponse>('AOEventsListResponse'),
          map(res => {
            return responseToResult(res, start);
          })
        ));
      });
  }

  getEventsForHomePage(start: number = 0, count: number = 1): Promise<AOEventsListHomePageResponse> {

    const requestUrl = `${this.config.serverUrlIonic}events/feed`;
    const params: AOEventsListHomePageRequestParams = {
      start: start,
      count: count,
      appId: this.contextService.currentContext.key
    }

    return firstValueFrom(this.authService.secureGet(requestUrl, { params: toAuthRequestParams(params) })
      .pipe(this.schemaValidator.isValidOperator<AOEventsListHomePageResponse>('AOEventsListHomePageResponse'),
        map(res => {
          return res
        })
      ));
  }

  getEvent(eventId: string): Promise<AOEvent> {
    // /api/ionic/events/short/:eventId?appId=trauma
    return this.getCurrentContextKey()
      .then(contextKey => {
        const url = `${this.config.serverUrlIonic}events/byId/${eventId}`;
        return firstValueFrom(this.authService.secureGet(url, {
          params: {
            appId: contextKey
          }
        }).pipe(
          this.schemaValidator.isValidOperator<AOEventResponse>('AOEventResponse'),
          map(res => {
            return res.result;
          })
        ));
      });
  }

  getUserEvents(start: number = 0, count: number = 9, onlyForLoggedIn: boolean = true,
    past: boolean = false, includeAll: boolean = false, includeSharable: boolean = false): Observable<EventsResponse> {
    const requestUrl = `${this.config.eventUrl}`;
    const requestParams = {
      start,
      count,
      appId: this.contextService.currentContext.key,
      onlyForLoggedIn,
      past,
      includeAll,
      includeSharable
    }
    return this.authService.secureGet<EventsResponse>(requestUrl, {
      params: toAuthRequestParams(requestParams)
    });
  }

  getAOEventParticipants(
    eventId: string,
    type: AOEventParticipantType,
    start: number,
    count: number): Promise<AOEventParticipantsResponse> {
    // /api/ionic/events/participants/:eventId/:type?start=0&count=20&appId=trauma
    return this.getCurrentContextKey()
      .then(contextKey => {
        const url = type ? `${this.config.serverUrlIonic}events/attendees/${eventId}/${type}` :
          `${this.config.serverUrlIonic}events/attendees/${eventId}`;

        const params: AOEventParticipantsRequestParams = {
          start: start,
          count: count,
          appId: contextKey
        };

        return firstValueFrom(this.authService.secureGet(url, { params: toAuthRequestParams(params) }).pipe(
          this.schemaValidator.isValidOperator<AOEventParticipantsResponse>('AOEventParticipantsResponse')
        ));
      });
  }

  getAOEventAttendees(eventId: string, start: number, count: number): Promise<AOEventParticipantsResponse> {
    return this.getAOEventParticipants(eventId, AOEventParticipantType.PARTICIPANT, start, count);
  }

  getAOEventContributors(eventId: string, start: number, count: number): Promise<AOEventParticipantsResponse> {
    return this.getAOEventParticipants(eventId, AOEventParticipantType.CONTRIBUTOR, start, count);
  }

  getAOEventContributorsAndParticipants(eventId: string, start: number, count: number): Promise<AOEventParticipantsResponse> {
    return this.getAOEventParticipants(eventId, null, start, count);
  }

  searchEvents(query: string, start: number = 0, count: number = 5): Promise<any> {
    return this.getCurrentContextKey()
      .then(contextKey => {
        const url = `${this.config.serverUrlIonic}events/search`;
        return firstValueFrom(this.authService.secureGet(url, {
          params: toAuthRequestParams({
            query: encodeURIComponent(query),
            start,
            count,
            appId: contextKey
          })
        }).pipe(
          this.schemaValidator.isValidOperator<AOEventsListResponse>('AOEventsListResponse'),
          map(res => {
            return responseToResult(res, start);
          })
        ));
      });
  }

  searchEventAttendees(
    eventId: string,
    query: string,
    start: number,
    count: number,
    excludedUserIds?: Array<string>,
    facultyOnly = false
  ): Promise<AOEventParticipantsResponse> {
    return this.getCurrentContextKey()
      .then(contextKey => {
        const url = `${this.config.serverUrlIonic}events/attendees/${eventId}/search`;
        return firstValueFrom(this.authService.secureGet(url, {
          params: toAuthRequestParams({
            query: encodeURIComponent(query),
            start,
            count,
            appId: contextKey,
            excludedUserIds: excludedUserIds || [],
            facultyOnly
          })
        }).pipe(this.schemaValidator.isValidOperator<AOEventParticipantsResponse>('AOEventParticipantsResponse')));
      });
  }

  getCurrentContextKey(): Promise<string> {
    const context = this.contextService.currentContext;
    if (!context) {
      return Promise.reject('Unknown context');
    }
    return Promise.resolve(context.key);
  }

  async openEvent(event: AOEvent, source?: string | ActionSource, checkIsAttendee?: boolean): Promise<void> {

    if (!event?._id) {
      return;
    }

    if (event?.eventType?.toLocaleLowerCase() === 'meeting') {

      if (event?.website) {
        this.linkOpenerService.open(event?.website);
      }

      return;
    }

    if (checkIsAttendee) {
      if (event?.isAttendee) {
        const userVerificationStatus = await this.verificationService.verify();

        if (userVerificationStatus === VerificationStatus.VERIFIED) {
          this.appNavController.openEvent(event?._id, source as ActionSource);
        }
      } else if (event?.website) {
        this.linkOpenerService.open(event?.website);
      }
    } else {
      const userVerificationStatus = await this.verificationService.verify();

      if (userVerificationStatus === VerificationStatus.VERIFIED) {
        this.appNavController.openEvent(event?._id, source as ActionSource);
      }
    }
  }
}
